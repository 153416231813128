import React from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

// constant
import constants from "../../../constants";

// services
import { sendToCT } from "../../../services/Clevertap";

import * as styles from "./ReferralForm.module.css";

const ReferralForm = props => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const { contact } = constants.DOCTOR_REFERRAL;

  const onSubmit = data => {
    if (data) {
      let dataSubmitted = true;
      props.handleLeadCreateByDoctor(data, dataSubmitted);

      // clevertap event
      sendToCT("Doctor_referral_submit_clicked", {
        action: "Clicked on Submit button",
        name: data.name,
        phone: data.phone,
        email: data.email || "",
        medicalCondition: data.medicalCondition || "",
      });
    }
    reset();
  };

  return (
    <>
      <div className={styles.refferalForm}>
        <div className={styles.refferalForm__hdngSec}>
          {/* Heading */}
          <h5>Enter referee details</h5>
          {/* Paragraph */}
          <p>
            Enter the person details to whom you want to <br></br> refer for
            Twin program
          </p>
        </div>
        {/* Form */}
        <div className={styles.refferalForm__formSec}>
          <form name="doctorReferralForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {/* Name */}
              <div className="col-md-12">
                <div
                  className={`form-group ${styles.refferalForm__formSec__inpSec}`}
                >
                  <label htmlFor="name">Full Name*</label>
                  <input
                    className={
                      errors.name ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Full name"
                    id="name"
                    type="text"
                    disabled={props.doctorDetails.refer === true ? false : true}
                    {...register("name", {
                      required: "Please enter your name",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid name",
                      },
                      maxLength: { value: 50, message: "Name is too long" },
                    })}
                  />
                  {errors.name && (
                    <span className="invalid-feedback">
                      {errors.name.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Phone */}
              <div className="col-md-12 mt-3">
                <div
                  className={`form-group ${styles.refferalForm__formSec__inpSec}`}
                >
                  <label htmlFor="phone">Phone*</label>
                  <input
                    className={`form-control letterSp1
                  ${errors.phone ? "is-invalid" : ""}
                `}
                    placeholder="Phone number"
                    id="phone"
                    type="text"
                    maxLength="10"
                    inputMode="numeric"
                    disabled={props.doctorDetails.refer === true ? false : true}
                    {...register("phone", {
                      required: "Please enter your phone number",
                      pattern: {
                        value:
                          /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid phone number",
                      },
                      maxLength: { value: 10, message: "Invalid phone number" },
                    })}
                  />
                  {errors.phone && (
                    <span className="invalid-feedback">
                      {errors.phone.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Medical Condtion */}
              <div className="col-md-12 mt-3">
                <div
                  className={`form-group ${styles.refferalForm__formSec__inpSec}`}
                >
                  <label htmlFor="medicalCondition">
                    Medical condition*
                  </label>
                  <input
                    className={
                      errors.medicalCondition
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    placeholder="Medical condition"
                    id="medicalCondition"
                    type="text"
                    disabled={props.doctorDetails.refer === true ? false : true}
                    {...register("medicalCondition", {
                      required: "Please enter the medical condition",
                      pattern: {
                        value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                        message: "Please enter valid medical condition",
                      },
                      maxLength: {
                        value: 200,
                        message: "Medical condition is too long",
                      },
                    })}
                  />
                  {errors.medicalCondition && (
                    <span className="invalid-feedback">
                      {errors.medicalCondition.message}
                    </span>
                  )}
                </div>
              </div>

              {/* Email */}
              {/* <div className="col-md-12 mt-3">
                <div
                  className={`form-group ${styles.refferalForm__formSec__inpSec}`}
                >
                  <label htmlFor="email">
                    Email <span>(Optional)</span>
                  </label>
                  <input
                    className={
                      errors.email ? "form-control is-invalid" : "form-control"
                    }
                    placeholder="Email id"
                    id="email"
                    type="email"
                    disabled={props.doctorDetails.refer === true ? false : true}
                    {...register("email", {
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                        message: "Please enter your valid email",
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="invalid-feedback">
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div> */}

              <div className={`col-md-12 ${styles.refferalForm__ctaSec}`}>
                <div className="buttonClass">
                  {/* <span className="errorclass d-block text-center text-danger">
                    {!isValid &&
                      isSubmitted &&
                      "Please fill all the fields correctly"}
                  </span> */}
                  <button
                    type="submit"
                    className={styles.refferalForm__ctaSec__cta}
                    id="liveToastBtn"
                    disabled={props.doctorDetails.refer === true ? false : true}
                  >
                    <div className="bookBtn" id="book-now">
                      <span
                        className={`d-flex align-items-center justify-content-center position-relative`}
                      >
                        <span className={`bookBtn`}>Submit</span>
                        <span
                          className={`icon-arrow-forward ${styles.refferalForm__ctaSec__icon}`}
                          aria-hidden="true"
                        ></span>
                      </span>
                    </div>
                    <span className={styles.refferalForm__ctaLoader}>
                      <Loader
                        type="ThreeDots"
                        color="#FFF"
                        height={30}
                        width={30}
                        visible={props.leadSubmitLoading}
                      />
                    </span>
                  </button>
                </div>
              </div>
              {/* This div only shows up when props.doctorDetails.refer value is false */}
              {props.doctorDetails != null &&
              props.doctorDetails.refer === false ? (
                <div>
                  <p className="text-danger pt-3">
                    It seems you don't have the permission to refer. Kindly
                    reach out to{" "}
                    <a href={`mailto:${contact.email}`} className="navLink">
                      <span style={{ textDecoration: "underline" }}>
                        drcare@twinhealth.com
                      </span>
                    </a>{" "}
                    for assistance.
                  </p>
                </div>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReferralForm;
