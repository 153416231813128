import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Loader from "react-loader-spinner";

//components
import Accordion from "./Accordian";

// css
import * as styles from "./ReferralDetails.module.css";

const ReferralDetails = props => {
  return (
    <div className={styles.referralDetails}>
      {props.loading ? (
        <span className={styles.referralDetails__loaderSec}>
          <Loader
            type="TailSpin"
            color="#FF9400"
            height={30}
            width={30}
            visible={props.loading}
          />
        </span>
      ) : (
        <div>
          <div className={styles.referralDetails__cntnSec}>
            {/* Heading */}
            <h3>Track referrals</h3>
            {/* Sub-Heading */}
            {props.doctorReferralDetails !== null &&
            props.doctorReferralDetails.length > 0 ? (
              <p>
                View status of your {props.doctorReferralDetails.length}{" "}
                referrals{" "}
              </p>
            ) : (
              <p>View status of your referrals </p>
            )}
          </div>
          {props.doctorReferralDetails !== null &&
          props.doctorReferralDetails.length > 0 ? (
            <div className={`${styles.referralDetails__accordianSec}`}>
              <div>
                <div>
                  <Accordion
                    doctorReferralDetails={props.doctorReferralDetails}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.referralDetails__imgSec}>
              <StaticImage
                src="../../../images/doctor/referral_img1.png"
                alt=""
                className="img-fluid"
                placeholder="blurred"
                quality={90}
                width={230}
                height={190}
              />
              <p>
                You haven’t referred yet. Start<br></br> refering now to track
                your referrals.
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferralDetails;
