// extracted by mini-css-extract-plugin
export var accImg = "ReferralDetails-module--accImg--ZpgXt";
export var accordianListSec = "ReferralDetails-module--accordianListSec--5x1rY";
export var accordian_colMob1 = "ReferralDetails-module--accordian_colMob1--sjgXQ";
export var accordian_colMob2 = "ReferralDetails-module--accordian_colMob2--z9fmC";
export var accordionBody = "ReferralDetails-module--accordionBody--NdhYa";
export var accordionBtn = "ReferralDetails-module--accordionBtn--WMK-A";
export var accordionBttmSec = "ReferralDetails-module--accordionBttmSec--Eq8Cu";
export var accordionItem = "ReferralDetails-module--accordionItem--BFHJA";
export var accordionTitle = "ReferralDetails-module--accordionTitle--EwfFs";
export var accordionTitle1 = "ReferralDetails-module--accordionTitle1--qH3CP";
export var accordionTitle2 = "ReferralDetails-module--accordionTitle2--z0EGJ";
export var collapsed = "ReferralDetails-module--collapsed--bSE3r";
export var content = "ReferralDetails-module--content--lDYqs";
export var darkHr = "ReferralDetails-module--darkHr--pHY6e";
export var darkHr1 = "ReferralDetails-module--darkHr1--CUevu";
export var dateSec = "ReferralDetails-module--dateSec--Q0qzl";
export var extraRead = "ReferralDetails-module--extraRead--QDiC1";
export var indexCs = "ReferralDetails-module--indexCs--oj2o3";
export var marginCo = "ReferralDetails-module--marginCo--FHBcG";
export var noMargin = "ReferralDetails-module--noMargin--QKCLE";
export var noPadding = "ReferralDetails-module--noPadding--+6J6q";
export var paddingBo = "ReferralDetails-module--paddingBo--bAGl1";
export var paddingCo = "ReferralDetails-module--paddingCo--NwSPF";
export var readContent = "ReferralDetails-module--readContent--3OEdM";
export var readText = "ReferralDetails-module--readText--+twCQ";
export var readText1 = "ReferralDetails-module--readText1--wcRsP";
export var referralDetails = "ReferralDetails-module--referralDetails--UJGe9";
export var referralDetails__accordianSec = "ReferralDetails-module--referralDetails__accordianSec--flRN-";
export var referralDetails__cntnSec = "ReferralDetails-module--referralDetails__cntnSec--o58TF";
export var referralDetails__imgSec = "ReferralDetails-module--referralDetails__imgSec--DPhBC";
export var referralDetails__loaderSec = "ReferralDetails-module--referralDetails__loaderSec--TVz71";