import React from "react";
import { format } from "date-fns";

//styles
import * as styles from "./ReferralDetails.module.css";

const Accordion = props => {
  return (
    <div
      className="accordion accordion-flush mobAccordion_docRef"
      id={"accordionExampleInclusions"}
    >
      <div>
        <hr className={`${styles.darkHr1}`} />
      </div>
      {props.doctorReferralDetails.map((item, index) => (
        <div
          key={index}
          className={`accordion-item bgLightgray ${styles.accordionItem}`}
        >
          <div
            className={`accordion-header accordionHeader accordionHeaderDoctor`}
            id={`heading${item.id}`}
          >
            <button
              className={`accordion-button d-flex align-items-start justify-content-start collapsed  ${styles.accordionBtn}`}
              type="button"
              data-bs-toggle={"collapse"}
              data-bs-target={`#collapse${item.id}`}
              aria-expanded={index === 0 ? "true" : "false"}
              aria-controls={`collapse${item.id}`}
            >
              <div className="col-11 col-md-11 col-lg-11">
                <div className={`row align-items-center ${styles.paddingCo}`}>
                  <div className="col-12 col-md-12 align-self-center col-lg-12">
                    <p className={`pt-2 ${styles.accordionTitle}`}>
                      {item.name}
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p
                        className={`pt-2 ${
                          item.status === "Active"
                            ? styles.accordionTitle2
                            : styles.accordionTitle1
                        }`}
                      >
                        {item.status}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
          <div
            id={`collapse${item.id}`}
            className={`accordion-collapse collapse ${styles.accordionBttmSec}`}
            aria-labelledby={`heading${item.id}`}
            data-bs-parent={"#accordionExampleInclusions"}
          >
            <div className={`accordion-body ${styles.noPadding}`}>
              {item.DoctorLeadLogs.map((data, i) => {
                return (
                  <div className={`row ${styles.accordianListSec}`} key={i}>
                    <div
                      className={`col-7 col-md-6 col-lg-6 offset-md-0 offset-lg-0 offset-4 ps-2 ps-md-0 ps-lg-0 ${styles.accordian_colMob1}`}
                    >
                      <p className={`${styles.accordionBody}`}>{data.status}</p>
                    </div>
                    <div
                      className={`col-4 col-lg-2 col-md-2 ${styles.accordian_colMob2}`}
                    ></div>
                    <div
                      className={`col-8 col-md-4 col-lg-4 offset-md-0 offset-4 pt-4 pt-md-0 pt-lg-0`}
                    >
                      <div className={styles.dateSec}>
                        <p>{format(new Date(data.createdAt), "dd MMM, yy")}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {index !== props.doctorReferralDetails.length - 1 && (
            <div className="col-12 col-md-12 col-lg-12">
              <hr className={styles.noMargin} />
            </div>
          )}
        </div>
      ))}

      <hr className={` ${styles.darkHr} ${styles.noMargin}`} />
    </div>
  );
};

export default Accordion;
