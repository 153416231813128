// extracted by mini-css-extract-plugin
export var errorToastClassName = "ReferralForm-module--errorToastClassName--Q6PEj";
export var refferalForm = "ReferralForm-module--refferalForm--kD-d8";
export var refferalForm__ctaLoader = "ReferralForm-module--refferalForm__ctaLoader--DTMfB";
export var refferalForm__ctaSec = "ReferralForm-module--refferalForm__ctaSec--27nPl";
export var refferalForm__ctaSec__cta = "ReferralForm-module--refferalForm__ctaSec__cta--1j70h";
export var refferalForm__ctaSec__icon = "ReferralForm-module--refferalForm__ctaSec__icon--aN8KD";
export var refferalForm__formSec = "ReferralForm-module--refferalForm__formSec--7fnBT";
export var refferalForm__formSec__inpSec = "ReferralForm-module--refferalForm__formSec__inpSec--rLwyI";
export var refferalForm__hdngSec = "ReferralForm-module--refferalForm__hdngSec--NlCJ-";
export var successToastClassName = "ReferralForm-module--successToastClassName--MWfwB";