import React, { useState } from "react";

// css
import * as styles from "./ToggleButton.module.css";

const ToggleButton = (props) => {
  const [toggleState, setToggleState] = useState("Refer");

  const updateToggleButtonValue = e => {
    props.handleToggleButton(e.target.value)
    setToggleState(e.target.value);
  };

  return (
    <div>
      <div className={`d-flex align-items-center`}>
        <button
          onClick={updateToggleButtonValue}
          value="Refer"
          className={
            toggleState && toggleState === "Refer"
              ? styles.toggleBtn__refer
              : styles.toggleBtn__track
          }
        >
          Refer
        </button>
        <button
          onClick={updateToggleButtonValue}
          value="Track"
          className={
            toggleState && toggleState === "Track"
              ? styles.toggleBtn__refer
              : styles.toggleBtn__track
          }
        >
          Track
        </button>
      </div>
    </div>
  );
};

export default ToggleButton;

// To change the background of buttons onClick

// toggleButton === Refer ? styles.toggleBtn__refer
// toggleButton === Track ? styles.toggleBtn__refer : styles.toggleBtn__refer
// toggleButton === Refer ? styles.toggleBtn__refer : styles.toggleBtn__track
