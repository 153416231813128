import React, { useState, useEffect } from "react";
import queryString from "query-string";
import Loader from "react-loader-spinner";
import { Link } from "gatsby";

// seo
import Seo from "../components/Seo";

// api actions
import {
  doValidateDoctorEmailId,
  doValidateLeadCreatedByDoctor,
  doValidateReferralDetailsDoctor,
} from "../actions/doctorReferralActions";

// hooks
import { useWindowSize } from "../hooks/useWindowSize";

// services
import { sendToCT } from "../services/Clevertap";

// components
import Header from "../components/DoctorReferral/Header/Header";
import ToggleButton from "../components/DoctorReferral/ToggleButton/ToggleButton";
import RefferalForm from "../components/DoctorReferral/ReferralForm/ReferralForm";
import ReferralDetails from "../components/DoctorReferral/ReferralDetails/ReferralDetails";
import FailureToast from "../components/Toasts/FailureToast";
import SuccessToast from "../components/Toasts/SuccessToast";

// css
import * as styles from "./doctor-referral.module.css";

const DoctorReferralHomePage = props => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [referralLoading, setReferralLoading] = useState(false); // Updates only when doctor referral track details api called
  const [doctorDetails, setDoctorDetails] = useState(null); // Contain doctor details based on his/her emailid
  const [doctorId, setDoctorId] = useState(null); // Contain doctor id
  const [doctorReferralDetails, setDoctorReferralDetails] = useState(null); // Contain details of all the users which doctor has referred
  const [toggleButtonValue, setToggleButtonValue] = useState("default"); // show refer form and track details for mobile view based on toggle button
  const [showFailureToast, setShowFailureToast] = useState(false); // show toast message when referral lead already exist in our system.
  const [showSuccessToast, setShowSuccessToast] = useState(false); // show toast message on successful referral lead by doctor.
  const [updatedReferral, setUpdatedReferral] = useState(false); // handles the call of doctor referral api when form submitted
  const [foundEmail, setFoundEmail] = useState(false); // handles view when user remove email from the url
  const [leadSubmitLoading, setLeadSubmitLoading] = useState(false); // handle loader when lead submitted by doctor

  const { email } = queryString.parse(props.location.search);

  const windowSize = useWindowSize();

  // To get doctor details based on his/her email id
  useEffect(() => {
    const handleDoctorDetails = async () => {
      try {
        // api call -> to get doctor details based on email id
        setLoading(true);
        const response = await doValidateDoctorEmailId(email);
        setDoctorDetails(response.data);
        setLoading(false);
        setDoctorId(response.data.id);
      } catch (error) {
        setLoading(false);
        console.error("Doctor referral email id server error", error);
        setError("Something went wrong");
      }
    };
    if (email) handleDoctorDetails();
    setFoundEmail(true);
  }, [email]);

  // To get doctor referral details based on his/her id
  useEffect(() => {
    const handleDoctorReferralsData = async () => {
      try {
        // api call -> to get doctor referral details based on email id
        if (doctorId && doctorId != null) {
          setReferralLoading(true);
          const response = await doValidateReferralDetailsDoctor(doctorId);
          setReferralLoading(false);
          setDoctorReferralDetails(response.data);
        }
      } catch (error) {
        setLoading(false);
        console.error("Doctor referral details server error", error);
        setError("Something went wrong");
      }
    };
    handleDoctorReferralsData();
  }, [doctorId, updatedReferral]);

  // Create referrals leads by doctor
  const handleLeadCreateByDoctor = async (data, dataSubmitted) => {
    let doctorLeadBody = {
      doctorId: doctorId,
      doctorName: doctorDetails.firstName + " " + doctorDetails.lastName,
      name: data.name,
      mobileNumber: data.phone,
      email: data.email || "",
      medicalCondition: data.medicalCondition || ""
    };
    try {
      setLeadSubmitLoading(true);
      setShowSuccessToast(false);
      await doValidateLeadCreatedByDoctor(doctorLeadBody);
      setLeadSubmitLoading(false);
      setShowSuccessToast(true);
      setUpdatedReferral(true); // To call the doctor referral details api
      if (dataSubmitted) setUpdatedReferral(false);
    } catch (error) {
      setLeadSubmitLoading(true);
      setError("Something went wrong");
      setShowFailureToast(true);
      setLeadSubmitLoading(false);
      console.error("Doctor referral creation server error", error);
    }
  };

  // This function is to handle view for Referral form & Track Sec
  // for mobile view screen and called in "ToggleButton Component"
  const handleToggleButton = value => {
    setToggleButtonValue(value);

    // clevertap events
    if (value === "Track") {
      sendToCT("Doctor_referral_track_tab_clicked", {
        action: "Clicked on Track toggle button",
      });
    } else {
      sendToCT("Doctor_referral_refer_tab_clicked", {
        action: "Clicked on Refer toggle button",
      });
    }
  };

  return (
    <div className="bgLightgray" style={{ minHeight: "100vh" }}>
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      <Header
        showQrFaqIcon={doctorDetails && doctorDetails != null ? true : false}
        windowSize={windowSize}
        qrValue={`${props.location.origin}${props.location.pathname}?email=${email}`}
        doctorDetails={doctorDetails}
        loading={loading}
        LinkTo="/doctor-referral-login"
      />

      {email ? (
        <div>
          {loading ? (
            <span className={styles.docRefPage__loaderSec}>
              <Loader
                type="TailSpin"
                color="#FF9400"
                height={30}
                width={30}
                visible={loading}
              />
            </span>
          ) : (
            <div>
              {doctorDetails && doctorDetails != null ? (
                <div>
                  {/* Doctor Name */}
                  <div className={styles.docRefPage__docNameSec}>
                    <h3>
                      Welcome, Dr. {doctorDetails.firstName}{" "}
                      {doctorDetails.lastName}
                    </h3>
                  </div>

                  {/* <ToggleButton /> */}
                  <div className={styles.docRefPage__toggleBtnSec}>
                    <ToggleButton handleToggleButton={handleToggleButton} />
                  </div>

                  {/* Handle Form and Tarck UI for desktop and mobile view */}
                  {toggleButtonValue === "default" ? (
                    // FOR DESKTOP VIEW ONLY
                    <div className={`container`}>
                      <div className="row">
                        <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                          {/* Referral Form */}
                          <RefferalForm
                            handleLeadCreateByDoctor={handleLeadCreateByDoctor}
                            error={error}
                            doctorDetails={doctorDetails}
                            leadSubmitLoading={leadSubmitLoading}
                          />
                        </div>
                        <div
                          className={`col-lg-6 col-md-6 col-xs-12 col-sm-12 ${styles.docRefPage__refDtlsSec}`}
                        >
                          {/* Track Details Component */}
                          <ReferralDetails
                            doctorReferralDetails={doctorReferralDetails}
                            loading={referralLoading}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    // FOR MOBILE VIEW ONLY
                    <div>
                      {toggleButtonValue === "Refer" ? (
                        <div>
                          {/* Referral Form */}
                          <RefferalForm
                            handleLeadCreateByDoctor={handleLeadCreateByDoctor}
                            error={error}
                            doctorDetails={doctorDetails}
                            leadSubmitLoading={leadSubmitLoading}
                          />
                        </div>
                      ) : (
                        <div>
                          {/* Track Details Component */}
                          <ReferralDetails
                            doctorReferralDetails={doctorReferralDetails}
                            loading={referralLoading}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {/* Toast components */}
                  <SuccessToast
                    position="top-center"
                    showSuccessToast={showSuccessToast}
                    delay={5000}
                    autohide={true}
                    text="You have successfully referred the member."
                    onClose={() => setShowSuccessToast(false)}
                  />
                  <FailureToast
                    position="top-center"
                    showFailureToast={showFailureToast}
                    delay={5000}
                    autohide={true}
                    text="The referral lead provided by you already exists in our system."
                    onClose={() => setShowFailureToast(false)}
                  />
                </div>
              ) : (
                <div className={styles.docRefPage__errMsg}>
                  <h3>
                    Your email isn’t registered with Twin, please use your
                    registered email address.
                  </h3>
                </div>
              )}
            </div>
          )}
        </div>
      ) : foundEmail ? (
        <div>
          <div className={styles.docRefPage__errMsg}>
            <h3>
              Please <Link to="/doctor-referral-login">login</Link> with your
              registered email address. {email}
            </h3>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DoctorReferralHomePage;
